import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Button,
  Dialog,
  FlexColumn,
  FlexRow,
  Spinner,
  TabBar,
  Text,
  UpdatePaymentIbanWizard,
  colors,
  mobileMediaQuery,
  spacing,
  stripeElementsZIndex,
  useAsyncCallback,
  useDialogVisibility,
  useTranslate,
} from '@orus.eu/pharaoh'
import { isFailure } from '@orus.eu/result'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useCallback, useEffect, useState, type FormEvent } from 'react'
import { trpc } from '../../../client'
import { useStripeElementsProps } from '../../../lib/stripe/hook'

import { SepaMandateValidationDialog } from '@orus.eu/pharaoh/src/components/features/iban-wizard/common/SepaMandateValidationDialog'
import { useAddStripePaymentMethod } from './add-stripe-payment-method-hook'

type UpdatePaymentProps = {
  sessionLessAuth:
    | {
        token: string
        lastNameLetters: string
      }
    | undefined

  navigateBack?: () => void
  backButton: boolean
}

export function UpdatePaymentForm({ navigateBack, backButton, sessionLessAuth }: UpdatePaymentProps): JSX.Element {
  const stripeElementProps = useStripeElementsProps()
  const translate = useTranslate()

  return (
    <FlexColumnWithAppropriateGapAndMargin>
      <Text variant="body2">{translate('payment_method_update_info')}</Text>
      <Elements {...stripeElementProps}>
        <UpdatePaymentElementWrapperForm
          navigateBack={navigateBack}
          backButton={backButton}
          sessionLessAuth={sessionLessAuth}
        />
      </Elements>
    </FlexColumnWithAppropriateGapAndMargin>
  )
}

const FlexColumnWithAppropriateGapAndMargin = styled(FlexColumn)`
  gap: ${spacing[70]};
  margin: 0;

  ${mobileMediaQuery} {
    gap: ${spacing[50]};
    margin: 0 ${spacing[60]};
  }
`

type UpdatePaymentElementWrapperFormProps = {
  navigateBack?: () => void
  backButton: boolean
  sessionLessAuth:
    | {
        token: string
        lastNameLetters: string
      }
    | undefined
}

function UpdatePaymentElementWrapperForm({
  navigateBack,
  backButton,
  sessionLessAuth,
}: UpdatePaymentElementWrapperFormProps): JSX.Element {
  const translate = useTranslate()
  const stripe = useStripe()
  const { addStripePaymentMethod } = useAddStripePaymentMethod()
  const elements = useElements()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [paymentMean, setPaymentMean] = useState<'sepa' | 'card'>('sepa')
  const [iban, setIban] = useState<string | undefined>()
  const [updatedFirstName, setFirstName] = useState<string | undefined>()
  const [updatedLastName, setLastName] = useState<string | undefined>()
  const [updatedEmail, setEmail] = useState<string | undefined>()
  const [updatedAddress, setAddress] = useState<string | undefined>()
  const [updatedCity, setCity] = useState<string | undefined>()
  const [updatedPostalCode, setPostalCode] = useState<string | undefined>()

  const isMissingInformation =
    !iban ||
    !updatedAddress ||
    !updatedCity ||
    !updatedEmail ||
    !updatedFirstName ||
    !updatedLastName ||
    !updatedPostalCode ||
    updatedAddress === '' ||
    updatedCity === '' ||
    updatedEmail === '' ||
    updatedFirstName === '' ||
    updatedLastName === '' ||
    updatedPostalCode === ''

  const onPaymentMeanChange = useCallback((tabId: string) => {
    if (tabId !== 'sepa' && tabId !== 'card') return
    setPaymentMean(tabId)
  }, [])

  const handleSubmit = useAsyncCallback(
    async (event?: FormEvent) => {
      event?.preventDefault()

      if (loading) return

      const successReturnUrl = new URL(document.location.href)
      successReturnUrl.searchParams.set('redirect_status', 'succeeded')

      if (paymentMean === 'sepa') {
        if (
          !iban ||
          !updatedAddress ||
          !updatedEmail ||
          !updatedCity ||
          !updatedFirstName ||
          !updatedLastName ||
          !updatedPostalCode
        )
          return
        const result = await trpc.paymentMethod.receiveIban.mutate({
          jwt: sessionLessAuth?.token,
          iban,
          firstName: updatedFirstName,
          lastName: updatedLastName,
          email: updatedEmail,
          address: updatedAddress,
          city: updatedCity,
          postalCode: updatedPostalCode,
        })
        if (isFailure(result)) {
          setErrorMessage(translate('sepa_error'))
          return
        }

        window.location.href = successReturnUrl.href
        return
      }

      if (!stripe || !elements) {
        return
      }

      addStripePaymentMethod({
        source: { type: 'user', sessionLessAuth },
        stripe,
        elements,
        setErrorMessage,
        setLoading,
        handleSuccess() {
          window.location.href = successReturnUrl.href
        },
      })
    },
    [
      paymentMean,
      stripe,
      elements,
      addStripePaymentMethod,
      sessionLessAuth,
      iban,
      updatedAddress,
      updatedEmail,
      updatedCity,
      updatedFirstName,
      updatedLastName,
      updatedPostalCode,
      translate,
      loading,
    ],
  )

  const {
    hide: hideSepaMandateValidationDialog,
    show: showSepaMandateValidationDialog,
    visible: isSepaMandateValidationDialogVisible,
  } = useDialogVisibility('sepa-mandate-validation')

  useEffect(() => {
    if (!iban || iban === '') {
      hideSepaMandateValidationDialog()
    }
  }, [hideSepaMandateValidationDialog, iban])

  return (
    <div
      css={css`
        padding-bottom: ${spacing[100]};
      `}
    >
      <form onSubmit={handleSubmit}>
        <TabBar
          size="large"
          fullWidth
          tabs={{
            sepa: translate('payment_method_sepa_debit'),
            card: translate('payment_method_card'),
          }}
          selectedTabId={paymentMean}
          onTabChange={onPaymentMeanChange}
          css={css`
            margin-bottom: ${spacing[70]};
          `}
        />
        {paymentMean === 'card' ? (
          <div
            css={css`
              min-height: 345px;
              position: relative;
            `}
          >
            <Spinner
              size="50"
              css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              `}
            />
            <div
              css={css`
                background-color: ${colors.white};
                z-index: ${stripeElementsZIndex};
                position: relative;
              `}
            >
              <PaymentElement />
            </div>
          </div>
        ) : (
          <div>
            <UpdatePaymentIbanWizard
              iban={iban}
              onIbanChange={setIban}
              onAddressChange={setAddress}
              onCityChange={setCity}
              onEmailChange={setEmail}
              onFirstNameChange={setFirstName}
              onLastNameChange={setLastName}
              onPostalCodeChange={setPostalCode}
              updatedAddress={updatedAddress}
              updatedCity={updatedCity}
              updatedEmail={updatedEmail}
              updatedFirstName={updatedFirstName}
              updatedLastName={updatedLastName}
              updatedPostalCode={updatedPostalCode}
            />
          </div>
        )}
        <ButtonRow>
          <Button onClick={navigateBack} variant="secondary" disabled={!backButton}>
            {translate('cancel')}
          </Button>
          {paymentMean === 'sepa' ? (
            <Button
              variant="primary"
              disabled={paymentMean === 'sepa' && isMissingInformation}
              onClick={showSepaMandateValidationDialog}
            >
              {translate('save')}
            </Button>
          ) : (
            <></>
          )}
          {paymentMean === 'card' ? (
            <Button isLoading={loading} type="submit" variant="primary">
              {translate('save')}
            </Button>
          ) : (
            <></>
          )}
        </ButtonRow>
        {isSepaMandateValidationDialogVisible ? (
          <SepaMandateValidationDialog
            onHide={hideSepaMandateValidationDialog}
            onSubmit={() => handleSubmit()}
            firstName={updatedFirstName ?? ''}
            iban={iban ?? ''}
            lastName={updatedLastName ?? ''}
            isUpdatePayment
            isLoading={loading}
          />
        ) : (
          <></>
        )}
      </form>

      {errorMessage ? (
        <Dialog size="small" title={translate('payment_method_update_error')} onClose={() => setErrorMessage(null)}>
          {errorMessage}
        </Dialog>
      ) : (
        <></>
      )}
    </div>
  )
}

const ButtonRow = styled(FlexRow)`
  gap: ${spacing[50]};
  margin: ${spacing[70]} 0 0 0;
  justify-content: flex-end;

  ${mobileMediaQuery} {
    flex-direction: column-reverse;
    justify-content: stretch;
  }
`
